import React, { useState, useEffect } from 'react';
// import { useHistory } from 'react-router-dom';

function WebApp() {

  // const history = useHistory();
  
  useEffect(() => {
    document.title = "Portfolio · Kafi"
  }, []);

  const webApps = [
    {
      image: '/portfolio/web-apps/lims.png',
      title: 'Livestock Insurance Management System',
      client: `Green Delta Insurance Ltd.`,
      description: `Bangladesh's first-ever Livestock Insurance Management System, developed for the joint venture between BRAC Bank and Green Delta Insurance Ltd. Livestock insurance coverage is given to more than 400 farmers countrywide by GDIC. Each farmer is given a mobile app, healthcards and NFC tags.<br/><br/>From farmer management to policy creation, NFC tag distribution to book-keeping, claim settlement to report generation- everything is managed by this massive web application.<br/><br/>Check this out about the insurance stuff: <a href='https://ifarmer.asia/insurances/green-delta-insurance-company-limited' rel='noreferrer' target='_blank'>https://ifarmer.asia/insurances/green-delta-insurance-company-limited</a>`,
      stack: 'Angular, Java Spring Boot, MySQL',
      bg: '#ececec'
    },
    {
      image: '/portfolio/web-apps/sns.png',
      title: 'Inventory Management System<br/>with POS',
      client: `Search n' Serve`,
      description: `An inventory management system which comes with integrated POS, developed for a local pharmacy. Pharmaceutical, category and medicine management, expiration alert, report generation- whatever an inventory management systen does, this one has it all.`,
      stack: 'PHP, jQuery, MySQL',
      demo: 'https://sns.bloomte.ch',
      bg: '#ececec'
    },
    {
      image: '/portfolio/web-apps/tpa.png',
      title: 'Health Insurance Management System<br/>(Third Party Administrator)',
      client: `GD Assist, Green Delta Insurance Ltd.`,
      description: `This TPA system sits between insurance companies, corporates and, local and international hospitals. In the case of insurance claims, this web-based application handles the claims processing for an employer that self-insures its employees.<br/><br/>TPA also manages insurance company's claims processing that includes providing medical documents, time to time patient's update and medical bill. Though patient can take cashless treatment, GOP to the hospital on the behalf of the patient is also managed by this application.<br/><br/>See details: <a href='https://www.gdassist.com/tpa/' rel='noreferrer' target='_blank'>https://www.gdassist.com/tpa/</a>`,
      stack: 'Angular, Java Spring Boot, MySQL',
      bg: '#ececec'
    },
    {
      image: '/portfolio/web-apps/sail.png',
      title: 'Weighbridge Management System',
      client: `Steel Authority of India Limited, India`,
      description: `Weighbridge software developed for the Steel Authority of India Limited. They have four weighbridges at the Chasnalla Colliery, Jharkhand and were using four separate offline softwares to manage their daily operations.<br/><br/>The challenge was to develop a cloud-based software which will connect all the weighing scales installed all over the colliery through different COM ports and automate day to day operation.`,
      stack: 'PHP, jQuery, MySQL',
      bg: '#ececec'
    },
    {
      image: '/portfolio/web-apps/dcampus.png',
      title: 'Digital Campus',
      client: `United Trust, United Group`,
      description: `A portal for students of eight schools and colleges run by United Trust. Students can log in and get updated on their regular curricular and co-curricular activities via this web-app. This total solutions includes-
      <ul>
        <li>Digital Academic Content</li>
        <li>Age-based Skill Development Programs</li>
        <li>Distance learning programs through audio/ video call</li>
        <li>Homework download & submission</li>
        <li>Online exam</li>
        <li>Notice board</li>
        <li>Class schedule</li>
        <li>Payment information</li>
        <li>Monthly evaluation by teachers</li>
        <li>Grades</li>
        <li>Attendances</li>
        <li>Academic calendar</li>
        <li>Teacher evaluation etc.</li>
      </ul>`,
      stack: 'React JS, Laravel, MySQL',
      bg: '#ececec'
    },
    {
      image: '/portfolio/web-apps/cmaps.png',
      title: 'CrimeMaps',
      // client: `N/A`,
      description: `This was one of my undergraduate course projects where users could report occurrences over the cloud. All they have to do is hit the site, give permission to accces their current location and submit a quick form.<br/><br/>The idea was to crowdsource crime activities, provide information to law enforcement agencies and help them analyze the data to reduce crimes by patterns, locations etc. Other publicly accessible features are-
      <ul>
        <li>Crime Maps</li>
        <li>Recent Incidents</li>
        <li>Quick Tips</li>
        <li>Search Crime Zones</li>
        <li>Crime Reporting</li>
        <li>Statistics</li>
      </ul>`,
      demo: 'https://cmaps.k4fi.com/',
      stack: 'PHP, Google Location Services, jQuery, MySQL',
      bg: '#ececec'
    },
  ]

  const [current, setCurrent] = useState(0);

  const [isLoading, loading] = useState(true);

  const next = (index) => {
    if(index === webApps.length - 1) setCurrent(0);
    else setCurrent(prev => prev + 1);
    loading(true);
  }

  return (
    <div className="section portfolio" id="portfolio" style={{ backgroundColor: webApps[current].bg }}>
      <div className="portfolio-top">
        <div className="portfolio-content" style={{ backgroundImage: isLoading ? `url('/loader.gif')` : 'unset' }}>
          <img onLoad={ () => { loading(false) } } src={ webApps[current].image } style={{ maxWidth: isLoading ? 0 : "100%", maxHeight: isLoading ? 0 : "calc(100vh - 140px)" }} alt="App"/>
        </div>
        <div className="portfolio-details">
          <p className="portfolio-title" dangerouslySetInnerHTML={{ __html: webApps[current].title }}></p>
          <p className="portfolio-client" dangerouslySetInnerHTML={{ __html: webApps[current].client }}></p>
          <p className="portfolio-description" dangerouslySetInnerHTML={{ __html: webApps[current].description }}></p>
        </div>
      </div>
      <div className="footer">
        <table>
          <tbody>
            <tr>
              <td><strong>Built with</strong></td>
              <td>: { webApps[current].stack }</td>
            </tr>
            {
              webApps[current].demo &&
              <tr>
                <td><strong>Demo</strong></td>
                <td>: <a rel="noreferrer" href={ webApps[current].demo } target="_blank">{ webApps[current].demo }</a></td>
              </tr>
            }
          </tbody>
        </table>
        <div>
          <p className="next-item" onClick={ () => { next(current) } }>Next →</p>
        </div>
      </div>
    </div>
  );
}

export default WebApp;
