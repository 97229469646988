import React, { useState, useEffect } from 'react';

function WebDesign() {

  useEffect(() => {
    document.title = "Portfolio · Kafi"
  }, []);

  const webDesigns = [
    {
      image: '/portfolio/web-design/pioniaglobal.jpg',
      title: 'Pionia Global',
      client: `N/A`,
      description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer pellentesque eleifend mi, id molestie sem semper vitae.<br/><br/>Morbi imperdiet sapien ut massa tempus vestibulum. Nulla facilisi. Quisque est lacus, laoreet at ante non, convallis egestas risus. Morbi fermentum dui eu nunc maximus laoreet. Aenean commodo lobortis semper.`,
      stack: 'WordPress',
      demo: 'https://pioniaglobal.com',
      bg: 'rgb(225, 255, 238)'
    },
    {
      image: '/portfolio/web-design/redienjapan.jpg',
      title: 'RedienJapan',
      client: `N/A`,
      description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer pellentesque eleifend mi, id molestie sem semper vitae.<br/><br/>Morbi imperdiet sapien ut massa tempus vestibulum. Nulla facilisi. Quisque est lacus, laoreet at ante non, convallis egestas risus. Morbi fermentum dui eu nunc maximus laoreet. Aenean commodo lobortis semper.`,
      stack: 'HTML, CSS, JavaScript, PHP',
      demo: 'https://redienjapan.com/',
      bg: 'rgb(225, 255, 238)'
    },
    {
      image: '/portfolio/web-design/shohoz.jpg',
      title: 'Shohoz',
      client: `N/A`,
      description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer pellentesque eleifend mi, id molestie sem semper vitae.<br/><br/>Morbi imperdiet sapien ut massa tempus vestibulum. Nulla facilisi. Quisque est lacus, laoreet at ante non, convallis egestas risus. Morbi fermentum dui eu nunc maximus laoreet. Aenean commodo lobortis semper.`,
      stack: 'HTML, CSS, jQuery',
      demo: 'https://k4fi.com/portfolio/shohoz',
      bg: 'rgb(225, 255, 238)'
    },
    {
      image: '/portfolio/web-design/sohatrade.jpg',
      title: 'Soha Trade Ltd.',
      client: `N/A`,
      description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer pellentesque eleifend mi, id molestie sem semper vitae.<br/><br/>Morbi imperdiet sapien ut massa tempus vestibulum. Nulla facilisi. Quisque est lacus, laoreet at ante non, convallis egestas risus. Morbi fermentum dui eu nunc maximus laoreet. Aenean commodo lobortis semper.`,
      stack: 'WordPress, WooCommerce',
      demo: 'https://sohatradebd.com/',
      bg: 'rgb(225, 255, 238)'
    },
    {
      image: '/portfolio/web-design/nutritionandcleanse.jpg',
      title: 'Nutrition and Cleanse',
      client: `N/A`,
      description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer pellentesque eleifend mi, id molestie sem semper vitae.<br/><br/>Morbi imperdiet sapien ut massa tempus vestibulum. Nulla facilisi. Quisque est lacus, laoreet at ante non, convallis egestas risus. Morbi fermentum dui eu nunc maximus laoreet. Aenean commodo lobortis semper.`,
      stack: 'WordPress',
      demo: '',
      bg: 'rgb(225, 255, 238)'
    },
    {
      image: '/portfolio/web-design/chipku.jpg',
      title: 'Chipku',
      client: `N/A`,
      description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer pellentesque eleifend mi, id molestie sem semper vitae.<br/><br/>Morbi imperdiet sapien ut massa tempus vestibulum. Nulla facilisi. Quisque est lacus, laoreet at ante non, convallis egestas risus. Morbi fermentum dui eu nunc maximus laoreet. Aenean commodo lobortis semper.`,
      stack: 'HTML, CSS, jQuery',
      demo: 'https://k4fi.com/portfolio/chipku',
      bg: 'rgb(225, 255, 238)'
    },
    {
      image: '/portfolio/web-design/puku.jpg',
      title: 'Puku',
      client: `N/A`,
      description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer pellentesque eleifend mi, id molestie sem semper vitae.<br/><br/>Morbi imperdiet sapien ut massa tempus vestibulum. Nulla facilisi. Quisque est lacus, laoreet at ante non, convallis egestas risus. Morbi fermentum dui eu nunc maximus laoreet. Aenean commodo lobortis semper.`,
      stack: 'HTML, CSS, jQuery',
      demo: 'https://k4fi.com/portfolio/puku',
      bg: 'rgb(225, 255, 238)'
    },
    {
      image: '/portfolio/web-design/scratchcoders.jpg',
      title: 'Scratch Coders',
      client: `N/A`,
      description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer pellentesque eleifend mi, id molestie sem semper vitae.<br/><br/>Morbi imperdiet sapien ut massa tempus vestibulum. Nulla facilisi. Quisque est lacus, laoreet at ante non, convallis egestas risus. Morbi fermentum dui eu nunc maximus laoreet. Aenean commodo lobortis semper.`,
      stack: 'HTML, CSS, jQuery',
      demo: 'https://k4fi.com/portfolio/scratchcoders',
      bg: 'rgb(225, 255, 238)'
    },
    {
      image: '/portfolio/web-design/tripcast.jpg',
      title: 'Trip Cast',
      client: `N/A`,
      description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer pellentesque eleifend mi, id molestie sem semper vitae.<br/><br/>Morbi imperdiet sapien ut massa tempus vestibulum. Nulla facilisi. Quisque est lacus, laoreet at ante non, convallis egestas risus. Morbi fermentum dui eu nunc maximus laoreet. Aenean commodo lobortis semper.`,
      stack: 'HTML, CSS, jQuery',
      demo: 'https://k4fi.com/portfolio/tripcast',
      bg: 'rgb(225, 255, 238)'
    },
  ]

  const [current, setCurrent] = useState(0);

  const [isLoading, loading] = useState(true);
  
  const next = (index, image) => {
    if(index === webDesigns.length - 1) setCurrent(0);
    else setCurrent(index + 1);
    loading(true);
  }

  const duration = (image) => {
    const img = new Image();
    img.src = image;
    let duration = (img.height / 1000) * 2;
    console.log(duration)
    return duration === 0 ? '10s' : (duration + 's');
  }

  return (
    <div className="section portfolio mobile-app" id="portfolio" style={{ backgroundColor: webDesigns[current].bg }}>
      <div className="portfolio-top">
        <div className="portfolio-content" style={{ backgroundImage: isLoading ? `url('/loader.gif')` : 'unset' }}>
          <div key={ current } className="portfolio-screen" id={ "portfolio-screen" } style={{ 
            backgroundImage: `url(`+webDesigns[current].image+`)`,
            animationName: 'animatedBackground',
            animationDuration: duration(webDesigns[current].image),
            animationIterationCount: 'infinite'
          }}>
          </div>
        </div>
        <div className="portfolio-details">
          <div className="mobile-app-details">
            <p className="portfolio-title" dangerouslySetInnerHTML={{ __html: webDesigns[current].title }}></p>
            <p className="portfolio-client" dangerouslySetInnerHTML={{ __html: webDesigns[current].client }}></p>
            <p className="portfolio-description" dangerouslySetInnerHTML={{ __html: webDesigns[current].description }}></p>
          </div>
          <div className="footer">
            <table>
              <tbody>
                <tr>
                  <td><strong>Built with</strong></td>
                  <td>: { webDesigns[current].stack }</td>
                </tr>
                {
                  webDesigns[current].demo &&
                  <tr>
                    <td><strong>Demo/ Live&nbsp;&nbsp;&nbsp;</strong></td>
                    <td>: <a target="_blank" rel="noreferrer" href={ webDesigns[current].demo }>{ webDesigns[current].demo }</a></td>
                  </tr>
                }
              </tbody>
            </table>
            <div>
              <p className="next-item" onClick={ () => { next(current, webDesigns[current].image) } }>Next →</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WebDesign;
