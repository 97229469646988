import React, { useState, useEffect } from 'react';

function MobileApp() {
  
  useEffect(() => {
    document.title = "Portfolio · Kafi"
  }, []);

  const mobileApps = [
    {
      image: '/portfolio/mobile-apps/globalscope.png',
      title: 'GlobalScope HR',
      client: `Global Scope, United Arab Emirates`,
      description: `Developed (and still integrating new features) for the employees of a UAE-based group of companies. Fast and easy way to log attendances, request leaves, get salary information and many more.`,
      stack: 'React Native',
      platform: 'Android, iOS',
      bg: '#e5e5f4'
    },
    {
      image: '/portfolio/mobile-apps/smartcampus.png',
      title: 'Cambrian Smart Campus',
      client: `BSB-Cambrian Education Group`,
      description: `This is a mobile app for the educational institutions powered by BSB-Cambrian Education Group for monitoring students' activities and performance records. Students also can log in and get updated on their regular curricular and co-curricular activities via this app.<br/><br/>The aim is to integrate students and institutions in an extensive IT environment provided by Cambrian School & College.<br/><br/>Major features are-
      <ul>
        <li>Dashboard: Summary of your activity</li>
        <li>Class Routine: Lookup for class time table</li>
        <li>Homeworks: See and submit your homework</li>
        <li>Attendance Report: Your attendance report</li>
        <li>Grade Book: Summary of your test results</li>
        <li>Payment History: Fees and charges history</li>
        <li>Subject Teachers: Know your teachers</li>
        <li>Notice Board: List of important notices</li>
      </ul>`,
      stack: 'React Native',
      platform: 'Android, iOS',
      bg: '#e5e5f4'
    },
    {
      image: '/portfolio/mobile-apps/cmaps_app.png',
      title: 'CrimeMaps',
      // client: `aaaaaa`,
      description: `This was one of my undergraduate course projects where users could report occurrences over the cloud.<br/><br/>The idea was to crowdsource crime activities, provide information to law enforcement agencies and help them analyze the data to reduce crimes by patterns, locations etc. Other publicly accessible features are-
      <ul>
        <li>Crime Maps</li>
        <li>Recent Incidents</li>
        <li>Quick Tips</li>
        <li>Search Crime Zones</li>
        <li>Crime Reporting</li>
        <li>Statistics</li>
      </ul>`,
      stack: 'React Native',
      platform: 'Android',
      bg: '#e5e5f4'
    },
  ]

  const [current, setCurrent] = useState(0);

  const [isLoading, loading] = useState(true);

  const next = (index) => {
    if(index === mobileApps.length - 1) setCurrent(0);
    else setCurrent(index + 1);
    loading(true);
  }

  return (
    <div className="section portfolio mobile-app" id="portfolio" style={{ backgroundColor: mobileApps[current].bg }}>
      <div className="portfolio-top">
        <div className="portfolio-content" style={{ backgroundImage: isLoading ? `url('/loader.gif')` : 'unset' }}>
          <img onLoad={ () => { loading(false) } } src={ mobileApps[current].image } style={{ maxWidth: isLoading ? 0 : "100%", maxHeight: isLoading ? 0 : "calc(100vh - 140px)" }} alt="App"/>
        </div>
        <div className="portfolio-details">
          <div className="mobile-app-details">
            <p className="portfolio-title" dangerouslySetInnerHTML={{ __html: mobileApps[current].title }}></p>
            <p className="portfolio-client" dangerouslySetInnerHTML={{ __html: mobileApps[current].client }}></p>
            <p className="portfolio-description" dangerouslySetInnerHTML={{ __html: mobileApps[current].description }}></p>
          </div>
          <div className="footer">
            <table>
              <tbody>
                <tr>
                  <td><strong>Built with</strong></td>
                  <td>: { mobileApps[current].stack }</td>
                </tr>
                <tr>
                  <td><strong>Platform</strong></td>
                  <td>: { mobileApps[current].platform }</td>
                </tr>
                {
                  mobileApps[current].demo &&
                  <tr>
                    <td><strong>Demo</strong></td>
                    <td>: <a href={ mobileApps[current].demo }>{ mobileApps[current].demo }</a></td>
                  </tr>
                }
              </tbody>
            </table>
            <div>
              <p className="next-item" onClick={ () => { next(current) } }>Next →</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MobileApp;
