import React, { useState, useEffect } from 'react';

function Contact() {

  const [text, setText] = useState('Impressed');

  const contact = ['Impressed? <sup><span>a bit?</span></sup>', 'Vallagse?']

  useEffect(() => {
    document.title = "Contact · Kafi"
    let text = 0;
    setText(text);
    const intervalId = setInterval(() => { 
      if(text === 1) text = 0;
      else text++;
      setText(text);
    }, 5000)
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="section" id="contact">
      <div className="intro">
        <p className="greetings fade" dangerouslySetInnerHTML={{ __html: contact[text] }}></p>
        <p className="greetings mbot-30">Hire me then.</p>
        <p>Email me to<br/><a href="mailto:saidulislamkafi@gmail.com" rel="noreferrer" target="_blank">saidulislamkafi@gmail.com</a></p>
        <br/>
        <p>Or call/ text at<br/><a href="tel:+13479447329" rel="noreferrer" target="_blank">+1 (347) 944-7329</a></p>
      </div>
    </div>
  );
}

export default Contact;
