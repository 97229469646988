import React, { useEffect, useState } from 'react';

function About() {

  const [activities, setActivity] = useState({
    reading: null,
    watched: null
  });

  useEffect(() => {
    document.title = "Kafi — Front-end Software Engineer";
    let data = sessionStorage.getItem('data');
    if(data) setActivity(JSON.parse(data));
    else activity();
  }, []);

  const activity = () => {
		fetch('/data.php', {
			method: 'GET',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
		}).then((response) => response.json())
		.then((responseData) => {
      let watched = responseData.watched.split('*');
      let activity = {
        reading: responseData.reading,
        watched: watched
      }
      sessionStorage.setItem('data', JSON.stringify(activity))
      setActivity(activity);
		});
  }

  return (
    <div className="section" id="landing">
      <div className="intro">
        {/* <img className="greetings-image" src={ "/portfolio/hello.png" } alt="Hello"/> */}
        <p className="greetings">Hello</p>
        <p>My name is <strong>Saidul Islam</strong>.</p>
        <p>I'm a <strong>Front-end Software Engineer</strong>.</p>
        <p>And I like watching movies and reading books.</p>
        <p className="next-item mtop-20">
          <a href="https://drive.google.com/file/d/1IaallHpCt9btwFr8-iCcWLUtTVQ8sqMb/view" target="_blank">Resume →</a>
        </p>
      </div>
      <div className="footer">
          {
            activities.reading &&
            <p>Reading now: { activities.reading }</p>
          }
          {
            activities.watched &&
            <div className="watched">
              Recently watched:&nbsp;
              {
                activities.watched.map((item, index) => (
                  <div key={ index }>
                    <a rel="noreferrer" target="_blank" href={ item.split('|')[1] }>{ item.split('|')[0] }</a>
                    { index < activities.watched.length - 1 && ', ' }
                  </div>
                ))
              }
            </div>
          }
        {/* <span onClick={ () => { seePortfolio() } }>Portfolio →</span> */}
      </div>
    </div>
  );
}

export default About;
