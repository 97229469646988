import React, { useEffect } from 'react';
import { Switch, Route, NavLink } from "react-router-dom";

import About from './About';
import WebApps from './WebApps';
import MobileApps from './MobileApps';
import WebDesign from './WebDesign';
// import Blog from './Blog';
import Contact from './Contact';

function App() {

  // let location = useLocation();

  // const [isLoaded, setLoaded] = useState(false);

  useEffect(() => {
    document.title = "Portfolio · Kafi";
    setTimeout(() => {
      document.getElementById('menu').classList.remove('menu-init');
      document.getElementById('content').classList.remove('content-init');
    }, 3000);
  }, []);
  
  const Menu = (props) => {
    return (
      <NavLink
        activeClassName="active"
        { ...props }>
        <img src={ props.icon } alt="Icon" /><span>{ props.title }</span>
      </NavLink>
    )
  }
  
  return (
    <div id="container">
      <div id="menu" className="menu-init">
        <Menu title="About"       icon="/icons/about.png"           exact to="/"/>
        <div className="separator">
          <hr/>
          <p>Portfolio</p>
        </div>
        <Menu title="Web Apps"    icon="/icons/web-app.png"         exact to="/web-apps"/>
        <Menu title="Mobile Apps" icon="/icons/mobile-app.png"      exact to="/mobile-apps"/>
        <Menu title="Web Design"  icon="/icons/web-design.png"      exact to="/web-design"/>
        <div className="separator">
          <hr/>
        </div>
        <a rel="noreferrer" href="https://uzbuk.blogspot.com/" target="_blank">
          <img src="/icons/blog.png" alt="Icon" /><span>Blog</span>
        </a>
        {/* <Menu title="Blog"        icon="/icons/blog.png"            exact to="/blog"/> */}
        <Menu title="Contact"     icon="/icons/contact.png"         exact to="/contact"/>
      </div>
      <div id="content" className="content-init">
        <Switch>
          <Route path="/web-apps">
            <WebApps/>
          </Route>
          <Route path="/mobile-apps">
            <MobileApps/>
          </Route>
          <Route path="/web-design">
            <WebDesign/>
          </Route>
          <Route path="/contact">
            <Contact/>
          </Route>
          {/* <Route path="/blog">
            <Blog/>
          </Route> */}
          <Route path="/">
            <About />
          </Route>
        </Switch>
      </div>
    </div>
  );
}

export default App;
